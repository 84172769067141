import React from "react"
import MyLink from "../components/link"
import Icon1 from "../assets/img/icons/laptop-coding.svg"
//import Icon2 from "../assets/img/icons/shopping-cart.svg"
import Icon3 from "../assets/img/icons/web-design.svg"
//import Icon4 from "../assets/img/icons/cloud-idea.svg"
import Icon5 from "../assets/img/icons/rocket.svg"
import Icon6 from "../assets/img/icons/search-in-browser.svg"
import Icon7 from "../assets/img/icons/networking-manager.svg"
import Icon8 from "../assets/img/icons/website.svg"
import Icon9 from "../assets/img/icons/megaphone.svg"

export default function OurServices() {
    return (
        <div id="our-services" className="component relative gradient-right-blue">
            <div className="container">
                <h2 className="text-center">Our Services<span className="symbol"><span></span></span></h2>
                <div className="w-full lg:w-4/5 mx-auto">
                    <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6">
                        <div className="card hover-card card-blue">
                            <MyLink to="/website-design-and-development/">
                                <Icon1 className="icon-white" />
                                <h3 className="text-border-left">Website Design & Development</h3>
                                <p>Beautiful, custom designed, hand-coded websites.</p>
                                <p><span className="btn">Learn More</span></p>
                            </MyLink>
                        </div>
                        <div className="card hover-card card-blue">
                            <MyLink to="/web-application-development/">
                                <Icon3 className="icon-white" />
                                <h3 className="text-border-left">Web Application Development</h3>
                                <p>Progressive web applications built on a trusted stack.<br />Who needs native apps?</p>
                                <p><span className="btn">Learn More</span></p>
                            </MyLink>
                        </div>
                        {/*
                        <div className="card hover-card card-blue">
                            <MyLink to="/ecommerce-website-development/">
                                <Icon2 className="icon-white" />
                                <h3 className="text-border-left">E-Commerce Development</h3>
                                <p>Sell your goods, own your platform, integrate anything.</p>
                                <p><span className="btn">Learn More</span></p>
                            </MyLink>
                        </div>
                        */}
                        {/*
                        <div className="card hover-card card-blue">
                            <MyLink to="/statically-generated-websites/">
                                <Icon4 className="icon-white" />
                                <h3 className="text-border-left">Statically Generated Websites </h3>
                                <p>Lightning fast HTML websites hosted on serverless architecture. Un-hackable.</p>
                                <p><span className="btn">Learn More</span></p>
                            </MyLink>
                        </div>
                        */}
                        <div className="card hover-card card-green">
                            <MyLink to="/hosting-services/">
                                <Icon7 className="icon-white" />
                                <h3 className="text-border-left">Managed Hosting Services</h3>
                                <p>Not your normal shared hosting. Find out what makes our offering unique.</p>
                                <p><span className="btn">Learn More</span></p>
                            </MyLink>
                        </div>
                        {/*
                        <div className="card hover-card card-green">
                            <MyLink to="/domain-registration-and-management/">
                                <Icon8 className="icon-white" />
                                <h3 className="text-border-left">Domain Registration &amp; Management</h3>
                                <p>Domain registration, DNS management services and more.</p>
                                <p><span className="btn">Learn More</span></p>
                            </MyLink>
                        </div>
                        */}
                        <div className="card hover-card card-purple">
                            <MyLink to="/digital-marketing/">
                                <Icon5 className="icon-white" />
                                <h3 className="text-border-left">Digital<br />Marketing</h3>
                                <p>We strategise, create and run digital marketing campaigns across various platforms.</p>
                                <p><span className="btn">Learn More</span></p>
                            </MyLink>
                        </div>
                        <div className="card hover-card card-purple">
                            <MyLink to="/digital-marketing/">
                                <Icon9 className="icon-white" />
                                <h3 className="text-border-left">Social Media<br />Marketing</h3>
                                <p>Grow your business, connect with your followers and advertise across networks.</p>
                                <p><span className="btn">Learn More</span></p>
                            </MyLink>
                        </div>
                        <div className="card hover-card card-purple">
                            <MyLink to="/search-engine-optimisation/">
                                <Icon6 className="icon-white" />
                                <h3 className="text-border-left">Search Engine Optimisation</h3>
                                <p>Boost your organic search result ranking with a perfectly optimised website.</p>
                                <p><span className="btn">Learn More</span></p>
                            </MyLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className="top-gradient-darker"></div>
        </div>
    )
}